
import React, { Component } from 'react'; 
import { Formik } from 'formik'
import * as Yup from 'yup'
import Input from './Input'
import Select from 'react-select'
import FileInput from './FileInput'
import Loader from '../assets/loader.gif'
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  SelfieWrapper,
  ErrorLabel,
  Description,
  ButtonWrapper,
  StyledButton,
  LoaderImg,
} from '../styled'


const genders = [
  { label: "Mujer", value: "0" },
  { label: "Hombre", value: "1" },
  { label: "Otro", value: "3" }
]
class Form extends Component {
  state={
    isFetching: false,
    hasPhotoAttach: false,
    ShowMustHavePhotoAttach: false
  }

  componentDidMount(){
    if(this.props.photo) {
      this.setState({hasPhotoAttach: true })
    }
  }  
  
  componentWillReceiveProps (nextProps) { 
    if(nextProps.photo !== this.props.photo) {
      this.setState({hasPhotoAttach: true,ShowMustHavePhotoAttach: false })
    }
} 

  getInitialValues = () => ({
    picture: this.props.photo
      })

  getSignUpSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required( 'El nombre es requerido')
      ,
      family_name: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required( 'El apellido es requerido'),
      dni: Yup.string()
        .matches(/^\+?[0-9]\d{6,14}$/, 'DNI invalido')
        .min(7, 'El DNI debe tener un mínimo de 7(ocho) caracteres')
        .max(8, 'El DNI debe tener un máximo de 8(nueve) caracteres')
        .required( 'El DNI es requerido'),
      gender: Yup.string()
        .required( 'El genero es requerido'),
      email: Yup.string()
        .email('El Email es inválido')
        .required( 'El email es requerido'),
      phone_number: Yup.string()
        //.matches(/^\+?[1-9]\d{6,14}$/, 'Teléfono invalido')
        //.length(13, 'El teléfono debe tener 13(trece) caracteres')
        .required( 'El teléfono es requerido'),
    })

        
  handleSubmit = async data => {
    if(this.props.photo){
      this.setState({isFetching: true});
      let dataFormatter = {
        name: data.name,
        family_name:  data.family_name,
        gender:  data.gender.value,
        dni:  data.dni,
        email:  data.email,
        phone_number:  data.phone_number,
        picture: this.props.photo.replace(/^data:image\/[a-z]+;base64,/, ""),
        invite_id: this.props.invitation,
      }
      let url = "https://w78035x0sk.execute-api.us-east-2.amazonaws.com/prod/api/invites/updateguestlanding"
      
      this.fetchUsersAsync(url,dataFormatter)
    }else{
        this.setState({ShowMustHavePhotoAttach: true});
    }
  }

  async fetchUsersAsync(url,data) {
    try {
        this.setState({...this.state, isFetching: true});
        fetch(url,{
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(result => {
          console.log(result);
            this.props.onSuccess(result)
            this.setState({data: result, isFetching: false})
        })
    } catch (e) {
        this.setState({...this.state, isFetching: false});
    }
  };
          
  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row marginTop={"5%"}>
        <Description>Para facilitar el acceso al barrio, por favor completá los siguientes datos:</Description>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper >
          <Input
            name="firstName" 
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={'Nombre'}
            type={'text'}
            rows={1}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper >
          <Input
            name="familyName" 
            value={values.family_name}
            onChange={({ target: { value } }) => setFieldValue('family_name', value)}
            placeholder={'Apellido'}
            type={'text'}
            rows={1}
            error={errors.family_name ? true : false}
          />
          {errors.family_name && <ErrorLabel>{errors.family_name}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper >
          <Input
            name="dni" 
            value={values.dni}
            onChange={({ target: { value } }) => setFieldValue('dni', value)}
            placeholder={'DNI'}
            type={'text'}
            rows={1}
            error={errors.dni ? true : false}
          />
          {errors.dni && <ErrorLabel>{errors.dni}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.phone_number || errors.type}>
        <InputWrapper >
          <Input
            name="phone" 
            value={values.phone_number}
            onChange={({ target: { value } }) => setFieldValue('phone_number', value)}
            placeholder={'54911********'}
            type={'text'}
            rows={1}
            error={errors.phone_number ? true : false}
          />
          {errors.phone_number && <ErrorLabel>{errors.phone_number}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper >
          <Input
            name="email" 
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={'Email'}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper display={"block"}>
          <Select
            name="gender" 
            className="select"
            width={"100vw"}
            value={values.gender}
            onChange={value => setFieldValue('gender', value)}
            options={genders}
            placeholder="Genero de su DNI"
            error={errors.gender ? true : false}
          />
          {errors.gender && <ErrorLabel>{errors.gender}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputLabel>{'Selfie'}</InputLabel>
        <SelfieWrapper width={33}>
          <FileInput 
            photo={this.props.photo}
            cameraShow={this.props.cameraShow}
            onChange={file => setFieldValue('picture', file)} 
          />
        </SelfieWrapper>
          {!this.state.ShowMustHavePhotoAttach ? <></> : <ErrorLabel>La foto es requerida</ErrorLabel>}
      </Row>
      <ButtonWrapper>
        {this.state.data ? <ErrorLabel>{this.state.data.message}</ErrorLabel> : ''}
        {this.state.isFetching ? <LoaderImg src={Loader}/> :  <StyledButton type="Submit">Generar invitación</StyledButton> }
       
      </ButtonWrapper>
    </FormContainer>
  )

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={false}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
  }

export default Form;
